/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Utils
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Swal from "sweetalert2";
import http from "utils/http";
import EngageTable from "./engage/table";
import BlastTable from "./blast/table";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";

function OrderHistory() {
  const route = useLocation().pathname.replace('/history', '').split("/").slice(1).join("/");
  const [searchKeyword, setSearchKeyword] = useState('')
  const [orders, setOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState({
    onPage: 1,
    totalData: 0,
    totalPage: 0,
  });
  const [filter, setFilter] = useState({
    platform: '',
    time: '',
    status: '',
  })

  const fetchAllOrders = async () => {
    const service_type = route.split("-");
    const response = await http.get(`/order/${service_type[0]}`);
    const result = await Promise.all(
      response.data.data.map(async (result) => {
        const fetchServiceType = await http.get(
          `/service/${result.service_id}`
        );
        const orderCode = result.order_code.split("-");
        
        return {
          time: new Date(result.created_at).toLocaleString("en-GB", {
            minute: "2-digit",
            hour: "2-digit",
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }),
          order_id: result.id,
          order_code: `REPORT-${orderCode[1]}`,
          service_type: fetchServiceType.data.data.name,
          quantity: result.order_qty,
          target: JSON.stringify(result.data),
          status: result.order_status,
          report: result.order_report,
        };
      })
    );
    setAllOrders(result)
  }

  const fetchOrders = async (pageNumber, filter=null) => {
    setIsLoading(true);
    try {
      const service_type = route.split("-");
      let url = `/order/${service_type[0]}?page=${pageNumber}`
      if(filter){
        const params = new URLSearchParams(filter).toString()
        url = `${url}&${params}`
      }
      const response = await http.get(url);
      const totalPage = Math.ceil(response.data.data.total / 10);
      const results = response.data.data.results;

      const fetchedOrders = await Promise.all(
        results.map(async (result) => {
          const fetchServiceType = await http.get(
            `/service/${result.service_id}`
          );
          const orderCode = result.order_code.split("-");

          return {
            time: new Date(result.created_at).toLocaleString("en-GB", {
              minute: "2-digit",
              hour: "2-digit",
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }),
            order_id: result.id,
            order_code: `REPORT-${orderCode[1]}`,
            service_type: fetchServiceType.data.data.name,
            quantity: `${result.order_executed <= 0 ? result.success : result.order_executed}/${result.order_qty}`,
            target: JSON.stringify(result.data),
            status: result.order_status,
            report: result.order_report,
          };
        })
      );

      setOrders(fetchedOrders);
      setPage({
        onPage: pageNumber,
        totalData: response.data.data.total,
        totalPage: totalPage,
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleSearch = (keyword) => {
    const filtered = allOrders.filter((order) => 
      order.time.includes(keyword) ||
      order.service_type.toLowerCase().includes(keyword) ||
      order.order_code.toLowerCase().includes(keyword) ||
      order.service_type.includes(keyword) ||
      order.order_code.includes(keyword)
    )
    setFilteredOrders(filtered)
    document.querySelector('#footer-pagination-table').style.display = 'none'
  }

  useEffect(() => {
    if(!Object.values(filter).every(value => value === '')){
      fetchAllOrders()
      fetchOrders(1, filter);
    }else{
      fetchAllOrders()
      fetchOrders(1);
    }
  }, [route, filter]);

  useEffect(() => {
    if(searchKeyword){
      handleSearch(searchKeyword)
    }else{
      setFilteredOrders([])
      if(document.querySelector('#footer-pagination-table')){
        document.querySelector('#footer-pagination-table').style.display = 'block'
      }
    }
  }, [searchKeyword])

  const handleNextPage = () => {
    if (page.onPage < page.totalPage) {
      if(!Object.values(filter).every(value => value === '')){
        fetchOrders(page.onPage + 1, filter);
      }else{
        fetchOrders(page.onPage + 1);
      }
    }
  };

  const handlePrevPage = () => {
    if (page.onPage !== 1) {
      if(!Object.values(filter).every(value => value === '')){
        fetchOrders(page.onPage - 1, filter);
      }else{
        fetchOrders(page.onPage - 1);
      }
    }
  };

  const handleViewTarget = (data) => {
    const convertJSON = (data) => {
      let result
      try {
        result = JSON.parse(data); // Remove the inner JSON.parse call
      } catch (error) {
        return false
      }
      return result
    }

    let result = convertJSON(data)
    let resultHtml = "";

    if(result == false) result = data
    result = JSON.parse(result)

    for (const key in result) {
      if (result.hasOwnProperty(key)) {
        // Check if the property exists in 'result'
        if (Array.isArray(result[key])) {
          const mergedArray = result[key].join("\n");
          resultHtml += `
            <label for="${key}" class="form-label">${
            result[key] && result[key].includes("http") ? "link" : key
          }</label><br/>
            <textarea id="${key}" class="form-textarea" disabled>${mergedArray}</textarea><br/>
          `;
        } else {
          resultHtml += `
            <label for="${key}" class="form-label">${
            result[key] && result[key].includes("://") ? "link" : key
          }</label><br/>
            <input type="text" id="${key}" value="${
            result[key]
          }" class="form-input" disabled><br/>
          `;
        }
      }
    }

    Swal.fire({
      title: "Target Data",
      html: resultHtml,
      showCloseButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      focusConfirm: false,
      cancelButtonText: "Close",
    });
  };

  if (isLoading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox mt={3} mb={8}>
          <Grid container>
            <BeatLoader color="#21D4FD" />
          </Grid>
        </SoftBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox display="flex" justifyContent="space-between" width="100%" mb={3}>
        <SoftBox width="30%">
          <SoftSelect
            placeholder="Platform"
            options={[
              { value: "Twitter", label: "Twitter" },
              { value: "Instagram", label: "Instagram" },
              { value: "Youtube", label: "Youtube" },
              { value: "Tiktok", label: "Tiktok" },
              { value: "Facebook", label: "Facebook" },
            ]}
            onChange={(e) => setFilter((curr) => ({
              ...curr,
              platform: e.value
            }))}
          />
        </SoftBox>
        <SoftBox width="30%">
          <SoftSelect
            placeholder="Time"
            options={[
              { value: "all", label: "All" },
              { value: "24 hours", label: "24 hours" },
              { value: "7 day", label: "7 day" },
              { value: "30 day", label: "30 day" },
            ]}
            onChange={(e) => setFilter((curr) => ({
              ...curr,
              time: e.value
            }))}
          />
        </SoftBox>
        <SoftBox width="30%">
          <SoftSelect
            placeholder="Status"
            options={[
              { value: "1", label: "Success" },
              { value: "2", label: "Retry" },
              { value: "3", label: "Process" },
              { value: "4", label: "Ended" },
              { value: "5", label: "Queue" },
            ]}
            onChange={(e) => setFilter((curr) => ({
              ...curr,
              status: e.value
            }))}
          />
        </SoftBox>
      </SoftBox>
      <SoftInput type="text" placeholder="search..." mb={3} onChange={(e) => setSearchKeyword(e.target.value)}/>
      {orders.length > 0 && !isLoading ? <SoftBox>
        <SoftBox mt={3} mb={8}>
          {route === "engage-history" && (
            <EngageTable
              orders={searchKeyword ? filteredOrders : orders}
              page={page}
              onLoading={isLoading}
              onViewTarget={handleViewTarget}
              onNextPage={handleNextPage}
              onPreviousPage={handlePrevPage}
              isPage={filteredOrders.length <= 0}
              fetchOrders={fetchOrders}
            />
          )}

          {route === "blast-history" && (
            <BlastTable
              orders={searchKeyword ? filteredOrders : orders}
              page={page}
              onLoading={isLoading}
              onViewTarget={handleViewTarget}
              onNextPage={handleNextPage}
              onPreviousPage={handlePrevPage}
              isPage={filteredOrders.length <= 0}
              fetchOrders={fetchOrders}
            />
          )}
        </SoftBox>
      </SoftBox> : <SoftBox display="flex" justifyContent="center" alignItems="center" height="60vh">
        <p>There is no data</p>
      </SoftBox>}
    </DashboardLayout>
  );
}

export default OrderHistory;
