import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Swal from "sweetalert2";
import { ClipLoader } from "react-spinners";
import http from "utils/http";

export default function FacebookShare() {
  const [total, setTotal] = useState(1);
  const [target, setTarget] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [maxTotal, setMaxTotal] = useState(1);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform form validation
    if (!total || !target) {
      setError("All fields are required");
      return;
    }

    if (total < 1) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Total can't less than 1!!",
      });
      return;
    } else if (total > maxTotal) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Cannot exceed ${maxTotal}!`,
      });
      return;
    }

    setError("");
    setIsLoading(true);

    try {
      const data = JSON.stringify({ link: target });
      const response = await http.post("order", {
        order_qty: total,
        service_id: "132",
        data: data,
      });

      console.log(response.data);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Blast successfully created",
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response.data.message,
      });
    }

    setIsLoading(false);
  };

  const fetchAccountsTotal = async () => {
    const accounts = await http.get("facebook");
    const activeCount = accounts.data.data.status_amount.filter(
      (account) => account.status == "Active"
    );
    if (activeCount.length > 0) {
      setMaxTotal(activeCount[0].amount);
    } else {
      setMaxTotal(0);
    }

  };

  useEffect(() => {
    fetchAccountsTotal();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} lg={7}>
        <SoftBox
          color="white"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
          md={6}
        >
          <SoftBox p={2}>
            <SoftTypography variant="h5" fontWeight="bold" mb={3}>
              Form Share
            </SoftTypography>
            <form onSubmit={handleSubmit}>
              <SoftBox mb={3}>
                <SoftInput
                  type="number"
                  placeholder="Total"
                  onChange={(e) => setTotal(e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value) {
                      const totalValidate = total
                        ? total < 1
                          ? 1
                          : total > maxTotal
                            ? maxTotal
                            : total
                        : 1;
                      setTotal(totalValidate);
                      e.target.value = totalValidate;
                    } else {
                      setTotal(1);
                      e.target.value = 1;
                    }
                  }}
                />
              </SoftBox>
              <SoftBox mb={3}>
                <SoftInput
                  type="text"
                  placeholder="Link eg. https://www.facebook.com/zuck/posts/pfbid02KQNwHpyyuXW22TZvrmxoypRFVofZsKufDu8HYxNkrqoj4KGxSzNPm2pttQ8SXvXMl"
                  onChange={(e) => setTarget(e.target.value)}
                />
                {error && (
                  <SoftTypography variant="body2" color="error" mb={3}>
                    {error}
                  </SoftTypography>
                )}
              </SoftBox>
              <SoftButton
                disabled={isLoading}
                type="submit"
                variant="contained"
                color="info"
              >
                {isLoading ? <ClipLoader size={20} /> : "Blast"}
              </SoftButton>
            </form>
          </SoftBox>
        </SoftBox>
      </Grid>
    </Grid>
  );
}
