import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { green, blue, orange, red, purple } from "@mui/material/colors";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

const OrderStatusCard = ({ history }) => {
  const statusData = [
    { label: "Processing", value: history.processing, icon: "hourglass_empty", color: orange[500], tooltip: "Orders currently being processed" },
    { label: "Processed", value: history.processed, icon: "check_circle", color: green[500], tooltip: "Orders successfully processed" },
    { label: "Retry", value: history.retry, icon: "refresh", color: red[500], tooltip: "Orders that need to be retried" },
    { label: "Today", value: history.today, icon: "today", color: blue[500], tooltip: "Orders processed today" },
    { label: "Weekly", value: history.weekly, icon: "date_range", color: purple[500], tooltip: "Orders processed this week" },
  ];

  return (
    <Grid container spacing={3}>
      {statusData.map((status, index) => (
        <Grid item xs={12} lg={3} key={index}>
          <Card>
            <SoftBox display="flex" alignItems="center" padding="16px">
              <Tooltip title={status.tooltip}>
                <Icon sx={{ fontSize: 36, color: status.color, marginRight: 2 }}>{status.icon}</Icon>
              </Tooltip>
              <div>
                <SoftTypography fontSize="14px" fontWeight="bold">{status.label}</SoftTypography>
                <SoftTypography fontSize="18px" fontWeight="medium">{status.value}</SoftTypography>
              </div>
            </SoftBox>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default OrderStatusCard;
