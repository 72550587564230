/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import SalesTable from "examples/Tables/SalesTable";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import Globe from "examples/Globe";
import MessageCard from "examples/Cards/MessageCard";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
import breakpoints from "assets/theme/base/breakpoints";

// Data
import reportsBarChartData from "layouts/dashboards/default/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboards/default/data/gradientLineChartData";

// SVG Icons
import Facebook from "assets/images/icons/platform/facebook.svg";
import Instagram from "assets/images/icons/platform/instagram.svg";
import Twitter from "assets/images/icons/platform/twitter.svg";
import Youtube from "assets/images/icons/platform/youtube.svg";

// Utils
import http from "utils/http";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import profile from '../../../assets/images/icons/profile.png'

// Components
import TroopsMapping from "./components/TroopsMapping";
import TroopsCard from "./components/TroopsCard";
import OrderStatusCard from "./components/OrderStatusCard";

// Data
import top9troops from "./data/top9troops";
import { PLATFROM_FACEBOOK, PLATFROM_INSTAGRAM, PLATFROM_TWITTER, PLATFROM_TIKTOK, PLATFROM_YOUTUBE } from "utils/const";
import { capitalizeFirstLetter } from "utils/helper";
import SoftSelect from "components/SoftSelect";
import { FaTiktok } from "react-icons/fa";

function Default() {
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState([]);
  const [engageStats, setEngageStats] = useState([]);
  const [blastStats, setBlastStats] = useState([]);
  const [summaryHistory, setSummaryHistory] = useState([]);
  const [currTopTroops, setCurrTopTroops] = useState({value: PLATFROM_FACEBOOK, label: capitalizeFirstLetter(PLATFROM_FACEBOOK)})
  const [topTroops, setTopTroops] = useState([])
  const { values } = breakpoints;
  const { size } = typography;
  const { chart, items } = reportsBarChartData;

  useEffect(() => {
    setIsLoading(true);
    Promise.all([fetchStats(), fetchEngageStats(), fetchBlastStats(), fetchSummaryHistory()])
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error("Error fetching data", error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true)
    getTopTroops(currTopTroops)
    setIsLoading(false)
  }, [currTopTroops])

  const getTopTroops = async (curr) => {
    try {
      const response = await http.get(`/${curr.value}?limit=4`)
      setTopTroops(response.data.data.results)
    } catch (error) {
      setTopTroops([])
    }
  }

  const fetchStats = async () => {
    try {
      const response = await http.get("statistics/active-account");
      setStats(response.data.data);
      return response.data.data; // Return the data from the promise
    } catch (error) {
      console.error("Error fetching stats", error);
      return []; // Return an empty array in case of an error
    }
  };

  const fetchEngageStats = async () => {
    try {
      const response = await http.get("statistics/engage-stats");
      setEngageStats(response.data.data);
      return response.data.data; // Return the data from the promise
    } catch (error) {
      console.error("Error fetching engage stats", error);
      return []; // Return an empty array in case of an error
    }
  };

  const fetchBlastStats = async () => {
    try {
      const response = await http.get("statistics/blast-stats");
      setBlastStats(response.data.data);
      return response.data.data; // Return the data from the promise
    } catch (error) {
      console.error("Error fetching engage stats", error);
      return []; // Return an empty array in case of an error
    }
  };

  const fetchSummaryHistory = async () => {
    try {
      const response = await http.get("statistics/summary-history");
      setSummaryHistory(response.data.data)
    } catch (error) {
      console.error("Error fetching engage stats", error);
      return []; // Return an empty array in case of an error
    }
  }

  if (isLoading || engageStats.length === 0 || blastStats.length === 0) {
    // Render loading spinner or skeleton component while data is being fetched
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox py={3}>
          <Grid container>
            <BeatLoader color="#21D4FD" />
          </Grid>
        </SoftBox>
      </DashboardLayout>
    );
  }

  const engageChart = {
    labels: [
      "Add",
      "Comment",
      "Like",
      "View",
      "Follow",
      "Reply",
      "Retweet",
      "Subscribe",
    ],
    datasets: [
      {
        label: "Facebook",
        color: "facebook",
        data: [
          engageStats.facebook.follow,
          engageStats.facebook.comment,
          engageStats.facebook.like,
          engageStats.facebook.view,
          0,
          0,
          0,
          0,
        ],
      },
      {
        label: "Instagram",
        color: "instagram",
        data: [
          0,
          engageStats.instagram.comment,
          engageStats.instagram.like,
          engageStats.instagram.view,
          engageStats.instagram.follow,
          0,
          0,
          0,
        ],
      },
      {
        label: "Twitter",
        color: "twitter",
        data: [
          0,
          0,
          engageStats.twitter.like,
          0,
          engageStats.twitter.follow,
          engageStats.twitter.reply,
          engageStats.twitter.retweet,
          0,
        ],
      },
      {
        label: "Youtube",
        color: "youtube",
        data: [
          0,
          engageStats.youtube.comment,
          engageStats.youtube.like,
          engageStats.youtube.view,
          0,
          0,
          0,
          engageStats.youtube.subscribe,
        ],
      },
    ],
  };

  const blastChart = {
    labels: ["Post", "Share", "DM", "Live", "Quote"],
    datasets: [
      {
        label: "Facebook",
        color: "facebook",
        data: [
          blastStats.facebook.post,
          blastStats.facebook.share,
          blastStats.facebook.dm,
          0,
          0,
        ],
      },
      {
        label: "Instagram",
        color: "instagram",
        data: [
          blastStats.instagram.post,
          0,
          blastStats.instagram.dm,
          blastStats.instagram.live,
          0,
        ],
      },
      {
        label: "Twitter",
        color: "twitter",
        data: [
          blastStats.twitter.post,
          0,
          blastStats.twitter.dm,
          0,
          blastStats.twitter.quote,
        ],
      },
    ],
  };

  return (
    <>
      {!isLoading && (
        <DashboardLayout>
          <DashboardNavbar />
          <SoftBox py={3}>
            <Grid container>
              <Grid item xs={12} lg={7}>
                <SoftBox mb={3} p={1}>
                  <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform="capitalize"
                    fontWeight="bold"
                  >
                    general statistics
                  </SoftTypography>
                </SoftBox>

                <Grid container>
                  <Grid item xs={12}>
                    <Globe
                      display={{ xs: "none", md: "block" }}
                      position="absolute"
                      top="10%"
                      right={0}
                      mt={{ xs: -12, lg: 1 }}
                      mr={{ xs: 0, lg: 10 }}
                      canvasStyle={{ marginTop: "3rem" }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={5}>
                    <SoftBox mb={3}>
                      <MiniStatisticsCard
                        title={{ text: "Facebook Account", fontWeight: "bold" }}
                        count={stats.facebook || 0}
                        icon={{ color: "info", component: <FacebookIcon /> }}
                      />
                    </SoftBox>
                    <SoftBox mb={3}>
                      <MiniStatisticsCard
                        title={{ text: "Twitter Account", fontWeight: "bold" }}
                        count={stats.twitter || 0}
                        icon={{ color: "info", component: <TwitterIcon /> }}
                      />
                    </SoftBox>
                    <SoftBox mb={3}>
                      <MiniStatisticsCard
                        title={{ text: "Titkok Account", fontWeight: "bold" }}
                        count={stats.tiktok || 0}
                        icon={{ color: "info", component: <FaTiktok /> }}
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <SoftBox mb={3}>
                      <MiniStatisticsCard
                        title={{
                          text: "Instagram Account",
                          fontWeight: "bold",
                        }}
                        count={stats.instagram || 0}
                        icon={{ color: "info", component: <InstagramIcon /> }}
                      />
                    </SoftBox>
                    <SoftBox mb={3}>
                      <MiniStatisticsCard
                        title={{ text: "Youtube Account", fontWeight: "bold" }}
                        count={stats.youtube || 0}
                        icon={{ color: "info", component: <YouTubeIcon /> }}
                      />
                    </SoftBox>
                  </Grid>
                </Grid>
              </Grid>
              
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <SoftBox mb={3} mr={0.5}>
                    <Card>
                      <SoftBox pt={4} px={4}>
                        <SoftBox mb={0.5}>
                          <SoftTypography variant="h6" fontWeight="medium">
                            Blast History Summary
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox p={4}>
                        <OrderStatusCard history={summaryHistory.blast}/>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <SoftBox mb={3} mr={0.5}>
                    <Card>
                    <SoftBox pt={4} px={4}>
                        <SoftBox mb={0.5}>
                          <SoftTypography variant="h6" fontWeight="medium">
                            Engage History Summary
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox p={4}>
                        <OrderStatusCard history={summaryHistory.engage}/>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SoftBox mb={3} mr={0.5}>
                    <Card>
                      <SoftBox pt={2} px={2}>
                        <SoftBox mb={0.5}>
                          <SoftTypography variant="h6" fontWeight="medium">
                            Troops Mapping
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox p={2}>
                        <TroopsMapping stats={stats} />
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SoftBox mb={3} mr={0.5}>
                    <Card>
                      <SoftBox pt={2} px={2}>
                        <SoftBox mb={0.5} display="flex" justifyContent="space-between" alignItems="center">
                          <SoftTypography variant="h6" fontWeight="medium">
                            Troops Overview
                          </SoftTypography>
                          <SoftBox display="flex" alignItems="center">
                            <SoftSelect
                              defaultValue={currTopTroops}
                              options={[
                                { value: PLATFROM_FACEBOOK, label: capitalizeFirstLetter(PLATFROM_FACEBOOK) },
                                { value: PLATFROM_INSTAGRAM, label: capitalizeFirstLetter(PLATFROM_INSTAGRAM) },
                                { value: PLATFROM_TWITTER, label: capitalizeFirstLetter(PLATFROM_TWITTER) },
                                { value: PLATFROM_TIKTOK, label: capitalizeFirstLetter(PLATFROM_TIKTOK) },
                                { value: PLATFROM_YOUTUBE, label: capitalizeFirstLetter(PLATFROM_YOUTUBE) },
                              ]}
                              onChange={(curr) => setCurrTopTroops(curr)}
                            />
                            <Link to={`/monitoring-account/${currTopTroops.value}`}>
                              <SoftTypography variant="h6" fontWeight="light" style={{ marginLeft: "5px" }}>See All</SoftTypography>
                            </Link>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox p={2}>
                        {topTroops.length > 0 ? <Grid container spacing={3}>
                          {topTroops.map((troop, idx) => (
                            <Grid item xs={8} sm={6} key={idx}>
                              <TroopsCard
                                props={{
                                  photo: troop.info && troop.info.hasOwnProperty('profile_picture') ? `data:image/png;base64, ${troop.info.profile_picture}` : profile,
                                  username: troop.info && troop.info.hasOwnProperty('username') ? troop.info.username : '-',
                                  name: troop.info && troop.info.hasOwnProperty('name') ? troop.info.name : '-',
                                  age: troop.info && troop.info.hasOwnProperty('age') ? troop.info.age : '-',
                                  dob: troop.info && troop.info.hasOwnProperty('dob') ? troop.info.dob : '-',
                                  hobby: troop.info && troop.info.hasOwnProperty('hobby') ? troop.info.hobby : '-',
                                  location: troop.location ? troop.location : '-',
                                  last_ip: troop.info && troop.info.hasOwnProperty('last_ip') ? troop.info.last_ip : '-',
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid> : <SoftBox display="flex" justifyContent="center" alignItems="center">
                          <SoftTypography variant="h6" my="25%">Empty</SoftTypography>
                        </SoftBox>}
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <SoftBox mb={3} mr={0.5} position="relative">
                    <SalesTable
                      title="Engagement Stats"
                      rows={[
                        {
                          platform: [Facebook, "Facebook"],
                          addfriend: engageStats.facebook.addfriend,
                          comment: engageStats.facebook.comment,
                          like: engageStats.facebook.like,
                          view: engageStats.facebook.view,
                        },
                        {
                          platform: [Instagram, "Instagram"],
                          comment: engageStats.instagram.comment,
                          follow: engageStats.instagram.follow,
                          like: engageStats.instagram.like,
                          view: engageStats.instagram.view,
                        },
                        {
                          platform: [Twitter, "Twitter"],
                          follow: engageStats.twitter.follow,
                          like: engageStats.twitter.like,
                          reply: engageStats.twitter.reply,
                          tweet: engageStats.twitter.retweet,
                        },
                        {
                          platform: [Youtube, "Youtube"],
                          comment: engageStats.youtube.comment,
                          like: engageStats.youtube.like,
                          subscribe: engageStats.youtube.subscribe,
                          view: engageStats.youtube.view,
                        },
                      ]}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <GradientLineChart
                    title="Engagement Chart"
                    height="14rem"
                    chart={engageChart}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <SoftBox mb={6} mr={0.5} position="relative">
                    <SalesTable
                      title="Blast Stats"
                      rows={[
                        {
                          platform: [Facebook, "Facebook"],
                          post: blastStats.facebook.post,
                          share: blastStats.facebook.share,
                          dm: blastStats.facebook.dm,
                        },
                        {
                          platform: [Instagram, "Instagram"],
                          post: blastStats.instagram.post,
                          live: blastStats.instagram.live,
                          dm: blastStats.instagram.dm,
                        },
                        {
                          platform: [Twitter, "Twitter"],
                          post: blastStats.twitter.post,
                          quote: blastStats.twitter.quote,
                          dm: blastStats.twitter.dm,
                        },
                      ]}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <GradientLineChart
                    title="Blast Chart"
                    height="14rem"
                    chart={blastChart}
                  />
                </Grid>
              </Grid>
            </Grid>
          </SoftBox>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default Default;
